.page-docs
  
  &__container
    overflow initial
  
  .docslist
    margin -40px -90px
    
    &-item
      width 248px
      background $color-white
      margin 40px 90px
      position relative
      
      &:before
        content: ""
        border-top 1px solid #333
        width 120px
        left -150px
        top 130px
        position absolute
        
      &:nth-of-type(3n-2)
        &:before
          display none
    
      &__link
        display block
        text-decoration none
        padding 0
      
      &__image
        width auto
        max-width 100%
        max-height 280px
        
        display block
        margin 0 auto
        
        box-shadow 0 6px 16px 2px rgba(0,0,0,0.3)
        
      &__title
        display block
        
        font-size 14px
        font-weight 500
        line-height 20px
        
        margin-top 32px
        text-align center
      