.page-used
  .cols
    margin-top 50px
    
    &__item
      h5
        font-size 20px
        font-weight 500
        margin 0
        margin-bottom -10px
        
      p
      ul
        font-family $alt-font-family
        
      ul
        reset-list()
        
      li
        position relative
        padding-left 30px
        margin 6px 0
        
        &:before
          content ""
          position absolute
          top 2px
          left 0
          
          background url('/static/images/ul_li_point.png') center no-repeat
          size 20px
        
      .shadow-box--height
        box-sizing border-box
        height 100%
        margin-bottom -1em
        
        h5
          margin-bottom 24px
        
        p
          font-family $base-font-family
          font-weight 500
          
      .title-icon
        position relative
        padding-left 68px
        margin-bottom 42px
        
        &__icon
          position absolute
          left 0
          top 50%
          transform translate3d(0,-50%,0)

  
  &__container
    overflow initial

  &__title
    text-align left

  .table-wrapper
    margin-top 44px
    margin-bottom 68px
    
    h5
      font-size 20px
      font-weight 500
      margin 0
      margin-bottom 38px
    
    table
      width 100%
      
      tr
        &:nth-of-type(2n-1)
          background rgba(67,77,119,0.1)
          
        &:nth-of-type(2n)
          background $color-white

      th
        text-align left
        font-size 14px
        font-weight 500
        
      th
      td
        padding-left 30px
        line-height 36px

      
  .used-image__wrapper
    .shadow-box
      padding 32px
      
    img
      display block
      width 100%