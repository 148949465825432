html
  font-family $base-font-family
  font-size $base-font-size
  font-weight $base-font-weight
  line-height $base-line-hight
  font-smoothing()
  
  letter-spacing 0.05em

body
  background-color $color-base-bg
  color $color-base
  
  display flex
  flex-wrap wrap
  align-content space-between

a
  color $color-base
  transition-property color, background-color
  transition-duration 150ms
  &:focus
    outline none

::selection
  background $color-blue
  color #fff
  text-shadow none
