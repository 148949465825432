.page-main
  &__title
    font-size 28px
    font-weight 500
    text-align center
        
  .main-list
    margin-top 72px
    position relative
    
    &:before
      content ""
      position absolute
      top 44px
      left 0
      right 0
      
      border-top 1px solid #333
    
    &__item
      width 25%
      margin 0
      padding 0 12px
      box-sizing border-box
      position relative
      z-index 2
      
    &__intro
      padding 0 32px
      box-sizing border-box
      font-family $alt-font-family
      
    &__image-box
      background #fff
      padding 0 50px
      
    &__title
      text-transform initial
      margin-bottom 0
      
      
  &__progress
    padding 85px 0 90px
    position relative
    background $color-white
    
    
    
    &-container
      position relative
      z-index 3
      
    &-btn
      padding 0 54px
      display table
      margin 40px auto 0
      
  &__colors
    background background url('/static/images/bg/main_2.png') center no-repeat
    background-size cover
    padding 85px 0 98px
    
    .page-main__title
      color $color-white
    
    .color-list
      justify-content space-between
      border 0
      padding 65px 0 40px
      
      &__item
        width 345px
        border 0
        padding 40px 24px 30px
    
      &__image-box
        img
          size 140px
          
      &__title
        margin-top 15px
        margin-bottom 4px
    
  &__about
    background background url('/static/images/bg/main_3.png') center no-repeat
    background-size cover
    padding 80px 0 90px
    
    &-container
      overflow initial
      
    .cols
      
      
      &__item
        &--padding
          padding-left 95px
        
        &-title
          font-size 28px
          font-weight 500
          
          margin 34px 0 20px
          text-transform uppercase
          
        &-content
          h5
            font-size 20px
            font-weight 500
            margin 0
            margin-bottom -10px
            
          p
            font-family $alt-font-family
            font-size 15px
            line-height 22px
            
        &-btn
          margin-top 20px
               
        .shadow-box
          padding 50px 48px
    
    
      