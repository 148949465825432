.page-about
  .cols
    margin-top 50px
    
    &__item
      h5
        font-size 20px
        font-weight 500
        margin 0
        margin-bottom -10px
        
      p
      ul
        font-family $alt-font-family
        
      ul
        reset-list()
        
      li
        position relative
        padding-left 30px
        margin 6px 0
        
        &:before
          content ""
          position absolute
          top 2px
          left 0
          
          background url('/static/images/ul_li_point.png') center no-repeat
          size 20px
        
      .shadow-box--height
        box-sizing border-box
        height 100%
        margin-bottom -1em
        
        h5
          margin-bottom 24px
        
        p
          font-family $base-font-family
          font-weight 500
          
      .title-icon
        position relative
        padding-left 68px
        margin-bottom 42px
        
        &__icon
          position absolute
          left 0
          top 50%
          transform translate3d(0,-50%,0)

  
  &__container
    overflow initial

  &__title
    text-align left

  &__history
    clearfix()
    
    &-content
      float left
      width 745px
      padding-left 15px
      padding-right 15px
      
      box-sizing border-box
      
      
    &-text
      margin 40px 0 0 0
    
    &-note
      position relative
      
      float left
      width 335px
      margin 82px 0 0 30px
      
      font-size 13px
      line-height 16px
      
      
        
  
        
  &__progress
    padding 50px 0
    position relative
    background $color-light-blue
    margin 50px 0
    

    
    &-container
      position relative
      z-index 2
      
    .main-list
      margin-top 65px
      
  .about-image__wrapper
    .shadow-box
      padding 32px
      
    img
      display block
      width 100%