/*
 * Border triangles utility.
 *
 * Synopsis:
 *   css-triangle: <size> <color> <direction>
 *
 * Examples:
 *
 *  css-triangle(5px #000 top)
 *
 *  yields:
 *    content: ''
 *    display: block
 *    width: 0
 *    height: 0
 *    border: inset 5px
 *    border-color: transparent transparent #000 transparent
 *    border-bottom-style: solid
 */

css-triangle($triangle-size, $triangle-color, $triangle-direction)
  content ''
  display block
  width 0
  height 0
  border inset $triangle-size
  if ($triangle-direction == top)
    border-color transparent transparent $triangle-color transparent
    border-bottom-style solid
  if ($triangle-direction == right)
    border-color transparent transparent transparent $triangle-color
    border-left-style solid
  if ($triangle-direction == bottom)
    border-color $triangle-color transparent transparent transparent
    border-top-style solid
  if ($triangle-direction == left)
    border-color transparent $triangle-color transparent transparent
    border-right-style solid


/*
 * Clearfix
 */

clearfix()
  &:after
    content ""
    display table
    clear both


/*
 * Hide text
 */

hide-text()
  text-indent 200%
  white-space nowrap
  overflow hidden


/*
 * Font rendering OS X
 */
 
font-smoothing(active = true)
  if active == true
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
  else
    -webkit-font-smoothing subpixel-antialiased
    -moz-osx-font-smoothing auto


/*
 * Sizes block
 */  
   
size($width, $height = $width)
  width $width
  height $height
  
width($width, $maxWidth = null)
  width $width
  if($maxWidth != null)
    max-width $maxWidth
    
height($height, $maxHeight = null)
  height $height
  if($maxHeight != null)
    max-height $maxHeight


pos-trbl($top, $right = $top, $bottom = $top, $left = $right)
  top $top
  right $right
  bottom $bottom
  left $left

absolute($top, $right = $top, $bottom = $top, $left = $right)
  position absolute
  
  if($top != none)
    top $top
  
  if($right != none)
    right $right
    
  if($bottom != none)
    bottom $bottom
  
  if($left != none)
    left $left
    
fixed($top, $right = $top, $bottom = $top, $left = $right)
  position fixed
  
  if($top != none)
    top $top
  
  if($right != none)
    right $right
    
  if($bottom != none)
    bottom $bottom
  
  if($left != none)
    left $left
  
cb($easing)
  $easings = {
    easeInSine:     cubic-bezier(0.47, 0, 0.745, 0.715),
    easeOutSine:    cubic-bezier(0.39, 0.575, 0.565, 1),
    easeInOutSine:  cubic-bezier(0.445, 0.05, 0.55, 0.95),
    easeInQuad:     cubic-bezier(0.55, 0.085, 0.68, 0.53),
    easeOutQuad:    cubic-bezier(0.25, 0.46, 0.45, 0.94),
    easeInOutQuad:  cubic-bezier(0.455, 0.03, 0.515, 0.955),
    easeInCubic:    cubic-bezier(0.55, 0.055, 0.675, 0.19),
    easeOutCubic:   cubic-bezier(0.215, 0.61, 0.355, 1),
    easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1),
    easeInQuart:    cubic-bezier(0.895, 0.03, 0.685, 0.22),
    easeOutQuart:   cubic-bezier(0.165, 0.84, 0.44, 1),
    easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1),
    easeInQuint:    cubic-bezier(0.755, 0.05, 0.855, 0.06),
    easeOutQuint:   cubic-bezier(0.23, 1, 0.32, 1),
    easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1),
    easeInExpo:     cubic-bezier(0.95, 0.05, 0.795, 0.035),
    easeOutExpo:    cubic-bezier(0.19, 1, 0.22, 1),
    easeInOutExpo:  cubic-bezier(1, 0, 0, 1),
    easeInCirc:     cubic-bezier(0.6, 0.04, 0.98, 0.335),
    easeOutCirc:    cubic-bezier(0.075, 0.82, 0.165, 1),
    easeInOutCirc:  cubic-bezier(0.785, 0.135, 0.15, 0.86),
    easeInBack:     cubic-bezier(0.6, -0.28, 0.735, 0.045),
    easeOutBack:    cubic-bezier(0.175, 0.885, 0.32, 1.275),
    easeInOutBack:  cubic-bezier(0.68, -0.55, 0.265, 1.55)
  }
  
  $easings[$easing]


/*
 * Center block
 */
 
center-block()
  display block
  margin-left auto
  margin-right auto


/*
 * Center with transform
 */
 
center-transform()
  position absolute
  top 50%
  left 50%
  transform translate3d(-50%, -50%, 0)


/*
 * Circle
 */
 
circle($size)
  width $size
  height $size
  border-radius 50%

/*
 * Ratio
 */
 
aspect-ratio($width = 16, $height = 9)
  position relative
  display block
  height 0
  padding 0
  overflow hidden
  padding-bottom ($height/$width*100)%


/*
 * Reset ul
 */
 
reset-list()
  margin-top 0 unless @margin-top
  margin-bottom 0 unless @margin-bottom
  padding-left 0 unless @padding-left
  list-style none
  
/*
 * !NOT MIXIN!
 * 
 * Footer Sticky
 * 
 * 
 * 
 */
 
footer-sticky($height)
  html
    position relative
    min-height 100%
    
  body
    margin-bottom $height
    
  footer
    position absolute
    left 0
    bottom 0
    width 100%
    height $height