.page-product
  background url('/static/images/bg-left-big.png') left top repeat-y, url('/static/images/bg-right-little.png') right top repeat-y
  
  &__content
    h5
      font-size 16px
      font-weight 700
      
      line-height 24px
      text-transform uppercase
      
      
  &__title-1
    margin-top 72px
    
  &__title-2
    font-size 34px
    line-height 40px
    text-transform uppercase
    font-weight 700
    margin 50px 0 32px
    
  &__subtitle-1
    margin-top 64px
    
    
.table
  width 100%
  text-transform uppercase
  border-collapse collapse
  
  tr
    border-bottom 1px solid #D5D5E2
    background $color-white
    
    &:nth-of-type(2n)
      background $color-light-blue
      
      
    td
    th
      &:nth-of-type(1)
        font-weight 700
        padding 20px
  
  td
  th
    padding 22px 0
    font-size 11px
    line-height 18px
    
  th
    font-size 14px
  
  &-title
    text-align left
    
    &__col
      font-weight 400
    
    &__first-col
      width 54%
      
  &__custom-width
    width 60%
    
  &--margin-top
    margin-top 64px
    
    tr:first-child
      border-top 1px solid #D5D5E2
      