.page-colors
  &__container
    overflow initial
  
  &__title
    font-size 11px
    line-height 24px
    text-transform uppercase
    font-weight 400
    
    margin 32px 0 12px


.color-list
  reset-list()
  
  display flex
  flex-wrap wrap
  
  border-left 1px solid #ccc
  border-top 1px solid #ccc
  
  &__item
    width 368px
    position relative
    
    border-right 1px solid #ccc
    border-bottom 1px solid #ccc

    padding 50px 24px 40px
    box-sizing border-box
    background $color-white
    
    transition transform 0.2s ease, box-shadow 0.2s ease
    
    &:hover
      transform translate3d(0,-2px,0)
      box-shadow 0 4px 25px 0px rgba(0,0,0,0.3)
      z-index 3
      
        
  &__link
    text-decoration none
  
  &__image-box
    position relative
    
    img
      display block
      size 150px
      margin auto
      border-radius 50%
    
  &__title
    display block
    font-size 20px
    font-weight 500
    line-height 24px
    
    margin-top 20px
    margin-bottom 10px
    text-align center
    color $color-base
    text-transform uppercase
    
  &__subtitle
    display block
    font-size 14px
    font-weight 500
    line-height 24px
    
    text-align center
    color $color-base
    
    
  &__search
    size 40px 40px
    background $color-white
    
    absolute none 8px 8px none
    
    opacity 0
    transition opacity 0.2s ease
    
    &-icon
      size 16px
      absolute 0
      margin auto
      
      background url('/static/images/icons/search.svg') center no-repeat
      background-size cover
      