.footer
  background-color $color-base
  padding 90px 0 65px
  
  &__logo
    img
      display block
  
  &__inner
    clearfix()
    margin-top 30px
  
  &-block
    float left
    margin-right 30px
    width 240px
    
    &--right
      float right
      text-align right
          
      position relative
      padding-top 24px
      
    
    &__title
      display block
      font-size 14px
      font-weight 500
      line-height 16px
      
      margin-bottom 6px
      
      color $color-white
    
    &__content
      display block
      color $color-white
      width 100%
      
      font-size 16px
      font-weight 500
      
      &--copyright
        font-size 14px
        opacity 0.7
    
      &--link
        text-decoration none
        
  &-socials
    reset-list()
    clearfix()
    display inline-block
    margin-bottom 6px
    
    &__item
      float left
      margin-left 10px
    
    &__link
      display block
      padding 6px
      position relative
      border-radius 50%

      &:before
        content ""
        absolute 0
        
        background #fff
        opacity 0.15
        border-radius 50%
        
        transition opacity 0.15s ease
        
      &:hover
        &:before
          opacity 0.25
      
      i
        display block
        size 16px
        position relative
        
        &.icon-vk
          background url('/static/images/socials/vk.png') no-repeat center
          
        &.icon-insta
          background url('/static/images/socials/insta.png') no-repeat center
          
        &.icon-twitter
          background url('/static/images/socials/twitter.png') no-repeat center