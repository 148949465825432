.page-news-detail
  &__main-container
    overflow initial

  &__container
    
    margin 0 auto
    
  &__title
    margin-top 0
    
    
      
   
    
  .news-content
    clearfix()
    margin-top 36px
    margin-bottom 50px
    
    &__date-box
      float left
      bottom 34px
      
    &__text
      font-size 16px
      line-height 22px
      
      font-family $alt-font-family
      
      p
        &:first-child
          margin-top 0
          
        &:last-child
          margin-bottom 0
          
          
    &__btn
      display table
      margin auto
          
 
      
  &__list
    position relative
    z-index 3