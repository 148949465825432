.page-contacts
  .page__container
    padding-bottom 0
  
  &__info
    position relative
    z-index 2
    
    &-container
      clearfix()
      overflow initial
     
  &--cols
    display flex
    justify-content space-between
    
    &:after
      display none
      
  &__map
    position relative
    margin-top 80px
    margin-bottom 90px
    
  
  .clear-title--text-initial
    text-transform initial
    
    font-size 20px
    font-weight 500
    
  .form
    margin-top 32px
    
    &__input
      display block
      width 100%
      
      font-size 16px
      font-weight 500
      line-height 44px
      
      padding 0 15px
      margin-bottom 16px
      
      outline none
      color $color-base
      background $color-white
      
      border 0
      border-bottom 1px solid rgba(18,22,40,0.5)
    
      box-sizing border-box
      
      &.error
        
        border-bottom 1px solid #ce0000
    
    &__btn-wrap
      margin-top 32px
    
    &__btn
      outline none
      cursor pointer
      border 0
      
      position relative
      width 100%
      margin-top 16px
      
      &:active
        top 2px
        
        
  .contact-data
    font-size 16px
    font-weight 500
    margin-top 5px
    color $color-base
    
    clearfix()
    
    &:first-child
      margin-top 0
    
    &__title
      float left
      display block
      
    &__content
      float right
      display block
      
    a
      text-decoration none
      
      
  #map
    size 100% 360px
    position relative
    
  .map-subtitle
    display block
    font-size 20px
    font-weight 500
    text-align center
    margin-bottom 30px
      
      
.contacts
  clearfix()
  
  &-block
    padding 70px 60px
    float left
    
    &__inner
      margin-top 40px
      
      &:first-child
        margin-top 0
    
    &__title
      position relative
      
    
        
      span
        position relative
    
  &-form
    padding 60px 92px
    float right
    
  
  