.page
  &__container
    clearfix()
    padding 80px 0
    
  &__title
    font-size 28px
    font-weight 500
    line-height 36px
    margin 0
    
    text-transform uppercase
    text-align center
    
    margin-bottom 40px

.page-error
  text-align center
  font-size 32px
  padding 120px 0


.content
  
  
  &--full
    width 100%
    
  &__title
    margin 0 0 46px
    font-size 28px
    font-weight 500
    line-height 36px
    
  &__subtitle
    margin 54px 0 48px
      
    
  &__image-wrapper
    position relative
    padding 34px
    background #fff
    box-shadow 0 0 15px 2px rgba(206,206,206,0.5)

    
  &__image
    display block
    width 100%
    
.sidebar
  float left
  width 330px
  
  &-list
    reset-list()
    
    &__item
      border 1px solid #D5D5E2
      border-top 0
      
      &:first-child
        border-top 1px solid #D5D5E2
    
    &__link
      display block
      
      font-size 11px
      line-height 64px
      
      text-transform uppercase
      text-decoration none
      
      background-color $color-white
      padding 0 25px
      
      &:hover
      &.is-active
        background-color $color-light-blue
        
      img
        width 40px
        margin auto
        
        absolute 0 none 0 14px
        
        
    &--with-icon
      .sidebar-list__link
        position relative
        padding-left 75px

  
.content
  float right
      
.list
  reset-list()
  display flex
  flex-wrap wrap
  
  margin -17px
  
  &__item
    width 345px
    margin 17px
    
        
    box-sizing border-box
    
    
      
.lnews-item
  background #fff
  
  &__link
    text-decoration none
    
    
            
        
  
  &__image-box
    position relative
    overflow hidden
    
    
    
  &__date
    font-size 14px
    font-weight 500
    absolute none none 0 0
    z-index 3
    
    transition background-color 0.15s ease

  &__image
    display block
    size 100% 205px
  
  &__content
    padding 17px 0
    
  &__title
    font-size 20px
    line-height 28px
    font-weight 500
    
    margin 0
    
  &__intro
    font-size 16px
    line-height 22px
    
    font-family $alt-font-family
    margin 10px 0 0

    
.date-box
  display inline-block
  
  font-size 14px
  line-height 24px
    
  
  color $color-white
  padding 4px 16px
  
  position absolute
  
  &:before
    content ""
    absolute 0
    
    background $color-base
    opacity 0.8
  
  
  span
    position relative
    