html
body
  size 100%
 
.container
  width 1105px
  margin 0 auto
  overflow hidden

.header
main
.footer
  width 100%
  min-width 1105px
  

svg
  fill rebeccapurple

.title
  position relative
    
  font-size 34px
  font-weight 700
  line-height 40px
  
  margin 0
  text-transform uppercase
    
  
    
  span
    display block
    position relative
   
.cols
  display flex
  justify-content space-between
  flex-wrap wrap
  
  &__item
    width 525px
    background $color-white 
    box-sizing border-box

.shadow-box
  background $color-white
  box-shadow 0 2px 11px 0 rgba(206,206,206,0.5)
  
  padding 60px 50px 75px
  
  &--image
    padding 30px
    
    img
      display block
      width 100%

.clear-title
  position relative
    
  font-size 34px
  font-weight 700
  line-height 40px
  
  margin 0
  text-transform uppercase
  
    
    
    
.subtitle
  position relative
    
  font-size 16px
  font-weight 700
    
  margin 0
  max-width 450px
  
  text-transform uppercase
    
  &:before
    css-triangle(60px, $color-orange, bottom)
    transform rotate(45deg)
    position absolute
    margin-left -60px
    
  span
    display block
    position relative
    padding-left 30px
    

.btn
  display inline-block
  padding 0 40px
  
  color $color-white
  background $color-base
  
  font-size 14px
  font-weight 500
  line-height 55px
  
  text-align center
  text-decoration none
  text-transform uppercase
  
.main-list
  reset-list()
  clearfix()
  
  display table
  margin auto
  
  &__item
    float left
    width 220px
    margin 0 32px
    text-align center
    
    
        
    
  &__image-box
    position relative
    display table
    margin auto
    
    img
      display block
      margin auto
      
      position relative
      
  &__title
    font-size 20px
    font-weight 500
    line-height 24px
    text-transform uppercase
    
  &__intro
    font-size 13px
    line-height 16px
  