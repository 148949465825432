.header
  background background url('/static/images/bg/header.png') center no-repeat
  background-size cover
  padding 44px 0 50px
  
  &--main
    background-image url('/static/images/bg/main_1.png')
    
  &__line
    display flex
    justify-content space-between
    margin-bottom 26px

  &__logo
    display block

    a
    img
      display block
      
    
  &__phone
    align-self center
    
    a
      color $color-base
      text-decoration none
    
  &__menu
    clearfix()
    width 100%


  .menu
    reset-list()
    display flex
    
    &__item
      width 14.285%
      
      border-right 1px solid #818181
      background-color $color-base
      transition background-color 0.15s ease
      
      &:last-child
        border-right 0
          
      &:hover
      &.active
        background #434D77
        
      
    &__link
      font-size 14px
      
      color $color-white
      text-decoration none
      text-align center
      
      text-transform uppercase
     
      display block
      line-height 70px
      
      
      
      
      
    &__inner
      position absolute
      top 64px
      
      margin-left -1px
      
      opacity 0
      pointer-events none
      
      transition opacity 0.15s ease
      
    
    
      
  
      

  &-title
    position relative
    padding 126px 0 126px
    
    color $color-base
    
    
      
    &__inner
      position relative
      max-width 550px
      
    h1
      font-size 36px
      font-weight 500
      line-height 46px
      
      margin 0
      
      letter-spacing 0.05em
      text-transform uppercase
      
    p
      margin 10px 0 38px
      font-size 16px
      font-weight 500
      line-height 22px
      text-transform uppercase
      
    a
      width 258px
      padding 0
      
      transition background 0.15s ease
      
      